import React, { Suspense, useState, useEffect } from 'react'
import { Router, Redirect, navigate } from '@reach/router'

import './App.css'
import ActiveProjects from './ActiveProjects'
import ImageDisplay from './ImageDisplay'
import Sales from './Sales'

const SLIDE_DURATION = 12000

const App = () => {
  const [slide, setSlide] = useState(0)
  const slides = [
    <ActiveProjects key={1} path="1" />,
    <ImageDisplay key={2} path="2" />,
    <Sales key={3} path="3" />,
    <ImageDisplay key={4} path="4" />,
    // Calendar not working at the moment so commented out until later
    // <Calendar key={5} path="5"></Calendar>,
    // <ImageDisplay key={6} path="6"></ImageDisplay>,
  ]

  const progressSlide = () => {
    const nextSlide = (slide + 1) % slides.length
    setSlide(nextSlide)
    navigate(`/${slide + 1}`)
  }

  useEffect(() => {
    let timer = setInterval(() => {
      progressSlide()
    }, SLIDE_DURATION)
    return () => clearInterval(timer)
  })

  return (
    <div className="App" onClick={progressSlide}>
      <Suspense fallback={<Loading />}>
        <Router className="router">
          <Redirect from="/" to="/1" />
          {slides}
        </Router>
      </Suspense>
    </div>
  )
}

const Loading = () => {
  return <div>loading...</div>
}

export default App
