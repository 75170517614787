import React, { useState, useEffect, useRef } from 'react'
import { scaleLinear, scaleOrdinal } from 'd3-scale'
import { schemeTableau10 } from '../Colors'
import { useDimensions } from '../Shared/useDimensions'

const ActiveProjectsGraph = props => {
  const [activeProjects, setActiveProjects] = useState([])
  const fetchProjects = async () => {
    const data = await fetch('/api/projectstats')
    const projects = await data.json()
    setActiveProjects(projects.activeProjects)
  }
  useEffect(() => {
    fetchProjects()
  }, [])

  const graphRef = useRef(null)
  const dim = useDimensions(graphRef)

  const padding = dim.width / 10
  const innerDim = { height: dim.height - padding * 3, width: dim.width - padding * 2 }

  const xScale = scaleLinear()
    .range([padding, innerDim.width])
    .domain([0, activeProjects.length])

  const yScale = scaleLinear()
    .range([innerDim.height, 0])
    .domain([
      0,
      // The scale goes to the maximum value, plus one extra for padding
      1 + activeProjects.map(p => p.count).reduce((prev, curr) => (curr > prev ? curr : prev), 0),
    ])

  const colorScale = scaleOrdinal(schemeTableau10)

  return (
    <div ref={graphRef} className="graph-container__graph">
      <svg {...dim}>
        <g transform={`translate(${padding}, ${padding})`}>
          <g>
            <text y={-20} x={-padding / 2} fontWeight="bold">
              Active Projects
            </text>
            <line x1={0} x2={0} y1={0} y2={innerDim.height} stroke="black" />
            {yScale.ticks(5).map((c, i) => {
              return (
                <g key={i} transform={`translate(0, ${yScale(c)})`}>
                  <text x={-5} y={5} textAnchor="end">
                    {c}
                  </text>
                  <line x1={-3} x2={3} y1={0} y2={0} stroke="black" />
                </g>
              )
            })}
          </g>
          <g transform={`translate(0, ${innerDim.height})`}>
            <line x1={0} x2={innerDim.width} y1={0} y2={0} stroke="black" />
            {activeProjects.map((p, i) => {
              return (
                <g key={i} transform={`translate(${xScale(i)},${0})`}>
                  <text y={20} textAnchor="middle">
                    {p.user}
                  </text>
                  <defs>
                    <clipPath id="myCircle">
                      <circle cx={0} cy={padding} r={padding / 2} fill="#FFFFFF" />
                    </clipPath>
                  </defs>
                  <image
                    clipPath="url(#myCircle)"
                    xlinkHref={`/profiles/${p.email}.jpg`}
                    width={padding}
                    height={padding}
                    x={-padding / 2}
                    y={padding / 2}
                  ></image>
                </g>
              )
            })}
          </g>
          {activeProjects.map((p, i) => {
            return (
              <g key={i} transform={`translate(${xScale(i) - padding / 2},${yScale(p.count)})`}>
                <rect width={padding} height={yScale(0) - yScale(p.count)} fill={colorScale(i)} />
                <text x={padding / 2} y="-4" textAnchor="middle">
                  {p.count}
                </text>
              </g>
            )
          })}
        </g>
      </svg>
    </div>
  )
}

export default ActiveProjectsGraph
