import React, { useState, useEffect, useRef } from 'react'
import { scaleLinear, scaleUtc } from 'd3-scale'
import { extent } from 'd3-array'
import { area } from 'd3-shape'
import { utcMonth } from 'd3-time'
import { format, parseISO, addDays } from 'date-fns'
import { useDimensions } from '../Shared/useDimensions'
import { schemeTableau10 } from '../Colors'

const DeliveredProjectsGraph = props => {
  const [deliveredProjects, setDeliveredProjects] = useState([])
  const fetchProjects = async () => {
    const data = await fetch('/api/projectstats')
    const json = await data.json()
    const length = json.deliveredProjects.length
    const projects = json.deliveredProjects.slice(length - 12, length)
    for (let project of projects) {
      project.date = parseISO(project.date)
    }
    setDeliveredProjects(projects)
  }
  useEffect(() => {
    fetchProjects()
  }, [])

  const graphRef = useRef(null)

  const dim = useDimensions(graphRef)
  const padding = dim.width / 10
  const innerDim = { height: dim.height - padding * 3, width: dim.width - padding * 2 }

  const timeInterval = extent(deliveredProjects.map(p => p.date))
  timeInterval[1] = addDays(timeInterval[1], 1)

  const xScale = scaleUtc()
    .range([padding / 2, innerDim.width - padding / 2])
    .domain(timeInterval)

  const yScale = scaleLinear()
    .range([innerDim.height, 0])
    .domain([
      0,
      // The scale goes to the maximum value, plus one extra for padding
      1 + deliveredProjects.map(p => p.count).reduce((prev, curr) => (curr > prev ? curr : prev), 0),
    ])

  return (
    <div ref={graphRef} className="graph-container__graph">
      <svg {...dim}>
        <g transform={`translate(${padding}, ${padding})`}>
          <g>
            <text y={-20} x={-padding / 2} fontWeight="bold">
              Delivered Projects
            </text>
            <line x1={0} x2={0} y1={0} y2={innerDim.height} stroke="black" />
            {yScale.ticks(5).map((c, i) => {
              return (
                <g key={i} transform={`translate(0, ${yScale(c)})`}>
                  <text x={-5} y={5} textAnchor="end">
                    {c}
                  </text>
                  <line x1={-3} x2={3} y1={0} y2={0} stroke="black" />
                </g>
              )
            })}
          </g>
          <g transform={`translate(0, ${innerDim.height})`}>
            <line x1={0} x2={innerDim.width} y1={0} y2={0} stroke="black" />
            {xScale.ticks(utcMonth.every(1)).map((c, i) => {
              return (
                <g key={i} transform={`translate(${xScale(c)}, 0)`}>
                  <text x={-5} y={15} textAnchor="end" transform="rotate(-45)">
                    {format(c, 'MMM-yy')}
                  </text>
                  <line x1={0} x2={0} y1={3} y2={-3} stroke="black" />
                </g>
              )
            })}
          </g>
          <g>
            <path d={projectsToPoints(deliveredProjects, xScale, yScale)} fill={schemeTableau10[0]} />
            {deliveredProjects.map((p, i) => {
              return (
                <g key={i} transform={`translate(${xScale(p.date)}, ${yScale(p.count)})`}>
                  <circle r={12} fill="white" opacity="0.7" />
                  <text textAnchor="middle" y="6">
                    {p.count}
                  </text>
                </g>
              )
            })}
          </g>
        </g>
      </svg>
    </div>
  )
}

const projectsToPoints = (projects, xScale, yScale) => {
  return area()
    .x(d => xScale(d.date))
    .y1(d => yScale(d.count))
    .y0(yScale(0) - 0.5)(projects)
}

export default DeliveredProjectsGraph
