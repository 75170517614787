import { useState, useEffect } from 'react'

export const useDimensions = ref => {
  const [dimension, setDimension] = useState({ width: 0, height: 0 })

  useEffect(() => {
    function resizeHandler() {
      setDimension({ width: ref.current.offsetWidth, height: ref.current.offsetHeight })
    }
    window.addEventListener('resize', resizeHandler, false)
    resizeHandler()
    return () => window.removeEventListener('resize', resizeHandler)
  }, [ref])

  return dimension
}
