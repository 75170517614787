export const schemeTableau10 = [
  '#76b7b2',
  '#4e79a7',
  '#f28e2c',
  '#e15759',
  '#59a14f',
  '#edc949',
  '#af7aa1',
  '#ff9da7',
  '#9c755f',
  '#bab0ab',
]
