import React, { useRef } from 'react'
import DeliveredProjectsGraph from './DeliveredProjectsGraph'
import ActiveProjectsGraph from './ActiveProjectsGraph'
import { useDimensions } from '../Shared/useDimensions'
import './style.scss'

const ActiveProjects = props => {
  const containerRef = useRef(null)

  const dimensions = useDimensions(containerRef)
  return (
    <div className="graph-page">
      <div ref={containerRef} className="graph-container">
        <ActiveProjectsGraph dim={dimensions} />
        <DeliveredProjectsGraph dim={dimensions} />
      </div>
    </div>
  )
}

export default ActiveProjects
