import React, { useState, useEffect } from 'react'
import './style.scss'

const ImageDisplay = props => {
  let [image, setImage] = useState('')

  const fetchImages = async () => {
    let images
    if (sessionStorage.hasOwnProperty('photos')) {
      images = JSON.parse(sessionStorage.getItem('photos'))
    } else {
      const data = await fetch('/api/photos')
      images = await data.json()
      sessionStorage.setItem('photos', JSON.stringify(images))
    }
    // let index = Number.parseInt(sessionStorage.getItem('photosIndex') || '0', 10)
    // index = (index + 1) % images.length
    // sessionStorage.setItem('photosIndex', index)
    let index = ~~(Math.random() * images.length)
    setImage(images[index])
  }

  useEffect(() => {
    fetchImages()
  }, [])

  return <div className="image-display" style={{ backgroundImage: `url(${image})` }} />
}

export default ImageDisplay
