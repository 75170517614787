import React, { useState, useEffect, useRef } from 'react'
import { scaleLinear, scaleUtc, scaleOrdinal } from 'd3-scale'
import { extent } from 'd3-array'
import { format, parseISO, addDays } from 'date-fns'
import { utcMonth } from 'd3-time'
import { schemeTableau10 } from '../Colors'
import { useDimensions } from '../Shared/useDimensions'
import numberAbbreviation from '../Shared/numberAbbreviation'

const SalesGraph = props => {
  const [orders, setOrders] = useState([])
  const fetchOrders = async () => {
    const data = await fetch('/api/upsales')
    const orders = await data.json()
    for (const order of orders.sales) {
      order.date = parseISO(order.date)
    }
    setOrders(orders.sales)
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  const graphRef = useRef(null)
  const dim = useDimensions(graphRef)

  const padding = dim.width / 10
  const innerDim = { height: dim.height - padding * 2, width: dim.width - padding * 2 }

  const timeInterval = extent(orders.map(o => o.date))
  timeInterval[1] = addDays(timeInterval[1], 1)

  const xScale = scaleUtc()
    .range([padding / 2, innerDim.width - padding / 2])
    .domain(timeInterval)

  const countScale = scaleLinear()
    .range([0, innerDim.height])
    .domain([
      0,
      // The scale goes to the maximum value, plus one extra for padding
      1 + orders.map(o => o.count).reduce((prev, curr) => (curr > prev ? curr : prev), 0),
    ])

  const totalScale = scaleLinear()
    .range([0, innerDim.height])
    .domain([0, orders.map(o => o.total).reduce((prev, curr) => (curr > prev ? curr : prev), 0)])

  const colorScale = scaleOrdinal(schemeTableau10)

  return (
    <div ref={graphRef} className="upsales-container__graph">
      <h3>Sales</h3>
      <svg {...dim} style={{ borderRadius: '1px solid black' }}>
        <g transform={`translate(${padding}, ${padding})`}>
          {/* Y-scale for count */}
          <g transform={`translate(0, ${innerDim.height})`}>
            <text textAnchor="middle" y={-10 - innerDim.height} fontWeight="bold">
              Orders
            </text>
            <line x1={0} x2={0} y1={0} y2={-innerDim.height} stroke={colorScale(0)} />
            {countScale.ticks(5).map((c, i) => (
              <g key={i} transform={`translate(0, ${-countScale(c)})`}>
                <text textAnchor="end" x={-5} y={5}>
                  {c}
                </text>
                <line x1={-3} x2={3} y1={0} y2={0} stroke={colorScale(0)} />
              </g>
            ))}
          </g>
          {/* Y-scale for total */}
          <g transform={`translate(${innerDim.width}, ${innerDim.height})`}>
            <text textAnchor="middle" y={-10 - innerDim.height} fontWeight="bold">
              Total (SEK)
            </text>
            <line x1={0} x2={0} y1={0} y2={-innerDim.height} stroke={colorScale(1)} />
            {totalScale.ticks(5).map((t, i) => (
              <g key={i} transform={`translate(0, ${-totalScale(t)})`}>
                <text x={5} y={5}>
                  {numberAbbreviation(t)}
                </text>
                <line x1={-3} x2={3} y1={0} y2={0} stroke={colorScale(1)} />
              </g>
            ))}
          </g>
          {/* X-scale */}
          <g transform={`translate(0, ${innerDim.height})`}>
            <line x1={0} x2={innerDim.width} y1={0} y2={0} stroke="black" />
            {xScale.ticks(utcMonth.every(1)).map((m, i) => {
              return (
                <g key={i} transform={`translate(${xScale(m)}, ${0})`}>
                  <text textAnchor="middle" y={20}>
                    {format(m, 'MMM-yy')}
                  </text>
                </g>
              )
            })}
          </g>
          {/* Graph */}
          <g transform={`translate(0, ${innerDim.height})`}>
            {orders.map((o, i) => {
              return (
                <g key={i} transform={`translate(${xScale(o.date)}, 0)`}>
                  <rect x={-padding / 3} y={-countScale(o.count)} width={padding / 3} height={countScale(o.count)} fill={colorScale(0)} />
                  <rect y={-totalScale(o.total)} width={padding / 3} height={totalScale(o.total)} fill={colorScale(1)} />
                </g>
              )
            })}
          </g>
        </g>
      </svg>
    </div>
  )
}

export default SalesGraph
