import React, { useState, useEffect } from 'react'

const LatestOrders = props => {
  const [orders, setOrders] = useState([])
  const fetchOrders = async () => {
    const data = await fetch('/api/upsales')
    const orders = await data.json()
    console.log(orders)
    setOrders(orders.latestOrders.slice(0, 6))
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  return (
    <div className="upsales-container__list">
      <h3>Latest Orders</h3>
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: 70 }}>Order ID</th>
            <th className="table-head__project text-left">Customer</th>
            <th className="table-head__project text-left">Description</th>
            <th className="text-right" style={{ width: 120 }}>
              Size
            </th>
            <th style={{ width: 50 }}>Sale</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, i) => {
            return (
              <tr key={i}>
                <td className="table--centered-text">{order.orderId}</td>
                <td className="table--centered-text text-left">{order.customer}</td>
                <td className="table--centered-text text-left">{order.project}</td>
                <td className="table--centered-text text-right">{`${order.total} ${order.currency}`}</td>
                <td className="table--centered-text">
                  <img src={`profiles/${order.user.email}.jpg`} alt="profile" className="profile-picture" />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default LatestOrders
