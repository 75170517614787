import React, { useRef } from 'react'
import { useDimensions } from '../Shared/useDimensions'
import SalesGraph from './SalesGraph'
import LatestOrders from './LatestOrders'
import './style.scss'

const Sales = props => {
  const containerRef = useRef(null)
  const dimensions = useDimensions(containerRef)
  return (
    <div className="upsales-page">
      <div ref={containerRef} className="upsales-container">
        <SalesGraph dim={dimensions} />
        <LatestOrders />
      </div>
    </div>
  )
}

export default Sales
